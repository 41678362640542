/* eslint-disable */
import { useState, useEffect, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import { Button, Spinner, toaster } from "evergreen-ui";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import injected from "../../utils/injected";
import GridBuy from "../../assets/svg/GRIDBUY.svg";
import Mystery from "../../assets/svg/buy/Mystery.svg";
import { abi } from "../../assets/ABI/Pigs.json";
import { MAX_PER_TX, PRICE_IN_ETH, CONTRACT_ADDRESS } from "../../config";

import SideCard from "../../components/SideCard";

const BuyContainer = styled.div`
  /* height: 100%; */
  /* width: auto; */
  /* margin: 1rem 2rem; */
  /* display: flex; */
  /* flex-direction: column; */
  /* background: url(${GridBuy}); */
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: fixed;
  right: 10px;
  top: 15%;
  height: 537px;
  width: 281px;
  margin: 0;
  border-radius: 10px;
  background: linear-gradient(90.75deg, #0f1241 0.71%, #1f2176 83.33%);
  z-index: 10;
  overflow: hidden;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.3rem;
`;

const Ruler = styled.hr`
  width: 100%;
`;

const Svg = styled.object`
  margin: 2.5rem 0 0 0;
`;

const PriceTag = styled.div`
  width: fit-content;
  padding: 6px 13px;
  text-align: center;
  margin: 0.5rem auto;
  border: 1px solid #1d5cff;
  border-radius: 10px;
  background-color: #180e35;
  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 36px;
    line-height: 43.2px;

    span {
      font-size: 0.9rem;
    }
  }
  h6 {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

const MintCart = styled.div`
  width: 100%;
  margin-top: auto;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  button {
    width: 100%;
    padding: 8px 16px;
    font-weight: bold;
    font-style: italic;
    line-height: 16px;
    text-align: center;
    color: white;
    height: 32px;
    /* border: none; */
    -webkit-appearance: none;
    appearance: none;
    outline: none;
  }
`;

const CartAmnt = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  input {
    width: 61px;
    height: 32px;
    background-color: #474d66;
    margin: 0 9px;
  }
  h2 {
    font-weight: bold;
    line-height: 24px;
  }
`;

const CartCounter = styled.h1`
  font-family: "bungee";
  min-width: 42px;
  text-align: center;
`;

const Loading = styled.div`
  margin: 0 auto;
  margin-top: auto;
`;

// const ConnectMetamask = styled(Button)`
//   background-color: orange;
//   :hover {
//     background-color: orange;
//   }
// `;

const Buy = ({ show }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState(1);
  const [hodlPigs, setHodlPigs] = useState(null);
  const { library, activate, active, account } = useWeb3React();
  useEffect(() => {
    if (!active) return;

    const contract = new library.eth.Contract(abi, CONTRACT_ADDRESS);
    setHodlPigs(contract);
  }, [active, library]);

  const onClose = () => {
    history.push("/");
  };

  const addCart = () => {
    if (cart + 1 < MAX_PER_TX + 1) setCart(cart + 1);
  };
  const minusCart = () => {
    if (cart - 1 > 0) setCart(cart - 1);
  };

  const mintPig = useCallback(() => {
    if (!active) return;
    setLoading(true);
    hodlPigs.methods
      .mintPig(cart)
      .send({ from: account, value: library.utils.toWei(PRICE_IN_ETH) * cart })
      .then((tx) => {
        console.log(tx);
        history.push("/");
        toaster.success("Transaction Submited");
      })
      .catch((e) => {
        toaster.danger(e.message);
        setLoading(false);
      });
  }, [cart, active, hodlPigs, library, setLoading, account, history]);

  return (
    <SideCard show={show} close={onClose}>
      <BuyContainer>
        <Title>MINT</Title>
        <Svg aria-label="Animated SVG" type="image/svg+xml" data={Mystery} />
        <PriceTag>
          <h2>
            0.05Ξ<span> each</span>
          </h2>
        </PriceTag>
        {loading ? (
          <Loading>
            <Spinner size={70} />
          </Loading>
        ) : (
          <MintCart>
            <CartAmnt>
              <FaMinus onClick={minusCart} />

              <CartCounter>{cart}</CartCounter>
              <FaPlus onClick={addCart} />
            </CartAmnt>
            {active ? (
              <Button onClick={mintPig} appearance="primary">
                MINT
              </Button>
            ) : (
              <Button onClick={() => activate(injected)} appearance="primary">
                Connect Metamask
              </Button>
            )}
          </MintCart>
        )}
      </BuyContainer>
    </SideCard>
  );
};

export default Buy;
