export const NODE_ENV = process.env.REACT_APP_NODE_ENV || "dev";
export const ALCHEMY_URL = process.env.REACT_APP_ALCHEMY_URL || "";
export const PUBLIC_URL = process.env.PUBLIC_URL || "";
export const MAX_PER_TX = Number(process.env.REACT_APP_MAX_PRE_TX);
export const PRICE_IN_ETH = process.env.REACT_APP_PRICE_IN_ETH;
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
export const TOTAL_SUPPLY = process.env.REACT_APP_TOTAL_SUPPLY;
export const OPEN_SEA_URL = process.env.REACT_APP_OPEN_SEA_URL;
export const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_URL;
export const IPFS_NODE_PREEFIX = process.env.REACT_APP_IPFS_NODE_PREEFIX;
export const TOKEN_URI = process.env.REACT_APP_TOKEN_URI;
