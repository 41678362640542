/* eslint-disable */
import Table from "./table";
import TableCell from "./table-cell";
import TableHead from "./table-head";
import TableRow from "./table-row";
import Text from "./text";

export default {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Text,
};
