import { useEffect, useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { isMobile } from "react-device-detect";

import Buy from "../../assets/svg/how/Buy.svg";
import Deposit from "../../assets/svg/how/Deposit.svg";
import Sell from "../../assets/svg/how/Sell.svg";
import Break from "../../assets/svg/how/Break.svg";

const HowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  text-align: start;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
  object {
    width: 50%;
    flex-grow: 50%;
    max-height: 550px;
    @media only screen and (max-width: 500px) {
      width: 100%;
      flex-grow: 100%;
    }
  }

  .react-reveal {
    /* margin: auto; */
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    @media only screen and (max-width: 500px) {
      width: 100%;
    }
  }
`;

const Text = styled.div`
  /* align-self: flex-start; */

  flex-grow: 50%;
  margin: auto;
  width: 27rem;
  @media only screen and (max-width: 500px) {
    width: 100%;
    flex-grow: 100%;
    flex-direction: column;
    margin: 0;
  }
  h2 {
    font-family: "Urbanist";
    font-size: 2.5rem;
    -webkit-text-stroke-width: 1px;
    letter-spacing: 2px;
    font-weight: 400;
  }

  h2.mint {
    color: #24eaff;
    /* -webkit-text-stroke-color: #24eaff; */
  }
  h2.deposit {
    color: #5ce079;
    /* -webkit-text-stroke-color: #5ce079; */
  }
  h2.sell {
    color: #ffcd1e;
    /* -webkit-text-stroke-color: #ffcd1e; */
  }
  h2.crack {
    color: #ff2440;
    /* -webkit-text-stroke-color: #ff2440; */
  }

  p.pad {
    margin-left: 0.4rem;
  }

  p {
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 25px;
    color: #e7e4ed;
    /* text-align: justify; */

    a {
      color: #1bc5d8;
      :visited {
        color: #1d7be6;
      }
      :hover {
        color: #24eaff;
      }
    }

    span {
      font-weight: bold;
    }
    span.warning {
      color: red;
    }
    span.eth {
      background-image: -webkit-linear-gradient(
        255deg,
        rgb(36, 234, 255),
        #a47af1
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    span.nft {
      background-image: -webkit-linear-gradient(
        255deg,
        rgb(74, 4, 238),
        #d400ff
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    span.emoji {
      font-size: 1.7rem;
    }
    code.code {
      font-family: monospace;
      padding: 1px 4px;
      border-radius: 5px;
      color: #da2525;
      font-weight: lighter;
      background-color: #2a2436;
    }
  }
`;

const How = () => {
  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", (e) => setFirstScroll(true));

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, [setFirstScroll]);
  return (
    <>
      <HowContainer>
        <Fade when={firstScroll}>
          <Text>
            <h2 className="mint">MINT</h2>
            <p>
              Once the pre-sale starts you can reserve a <span>HODLPIG</span>{" "}
              before they are revealed a week after. Each <span>HODLPIGS</span>{" "}
              is unique, generated from over 180 possible attributes and vary in
              rarity. Each pig can store <span className="eth">Ether</span> like
              a wallet can.
            </p>
            <p className="pad">
              <span className="emoji">💎</span> <span>10,000</span> Rare,
              first-of its kind <span className="nft">NFT</span> piggy banks.
            </p>
            <p className="pad">
              <span className="emoji">⚖️</span> Fair Distribution, Fair Launch.
            </p>
            <p className="pad">
              <span className="emoji">📈</span> <span>0.05</span>{" "}
              <span className="eth">ETH</span> each, no{" "}
              <a
                href="https://coinmarketcap.com/alexandria/glossary/bonding-curve"
                target="_blank"
                rel="noreferrer"
              >
                bonding curve
              </a>
              .
            </p>
            <p className="pad">
              &nbsp;<span className="emoji">🔥</span>&nbsp;
              <span className="warning">Burnable</span> finite supply, see below
            </p>
          </Text>
          {isMobile ? (
            <></>
          ) : (
            <object aria-label="Buy icon" type="image/svg+xml" data={Buy} />
          )}
        </Fade>
        <Fade>
          {isMobile ? (
            <></>
          ) : (
            <object aria-label="Buy icon" type="image/svg+xml" data={Deposit} />
          )}
          <Text>
            <h2 className="deposit">DEPOSIT</h2>
            <p>
              <span>The</span> HODLPIGS contract implements a deposit function.
              This allows anyone to deposit Ether into any other HODLPIG.
            </p>
            <p>
              <span className="warning">WARNING: </span> Do <span>NOT</span>{" "}
              send any tokens or other NFT's to the contract address, the pigs
              are not wallets, to deposit you must interact with the contract's{" "}
              <code className="code"> deposit</code> function.
            </p>
          </Text>
        </Fade>
        <Fade>
          <Text>
            <h2 className="sell">SELL</h2>
            <p>
              <span>HODLPIGS</span> hold their balance when traded. Trading a
              pig that has 1 <span>Eth</span> in it, it will by default be worth
              1 <span>Eth</span> minimum.
            </p>
            <p>
              This makes <span>HODLPIGS</span> great for gifting. Think of your{" "}
              <span>HODLPIG</span> as a mini <span>eth</span> wallet, that you
              can trade.
            </p>
          </Text>
          {isMobile ? (
            <></>
          ) : (
            <object aria-label="Buy icon" type="image/svg+xml" data={Sell} />
          )}
        </Fade>
        <Fade>
          {isMobile ? (
            <></>
          ) : (
            <object aria-label="Buy icon" type="image/svg+xml" data={Break} />
          )}
          <Text>
            <h2 className="crack">CRACK</h2>
            <p>
              To retrive the stored <span>ETH</span>, break your{" "}
              <span>HODLPIG</span> open by calling the contract's crack function
              or through your <span>HODLPIG</span>'s page.
            </p>
            <p>
              The <code className="code">crackPig</code> function sends you all
              the stored <span>ETH</span> and{" "}
              <span className="warning">burns</span> the NFT.
            </p>
            <p>
              Once a <span>HODLPIG</span> is{" "}
              <span className="warning">burned</span> it is removed from the
              total supply and can never be minted again. This makes any{" "}
              <span>HODLPIG</span> increasingly rare.
            </p>
          </Text>
        </Fade>
      </HowContainer>
    </>
  );
};

export default How;
