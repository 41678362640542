/* eslint-disable */
import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";
import { BsTriangleFill } from "react-icons/bs";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./index.css";

const TriContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`;
const TriangleSVG = styled(BsTriangleFill)`
  align-self: center;
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  right: -200%;
  top: 65%;
  width: 100px;
  height: 100px;

  h3 {
    font-size: 1rem;
    margin: 8px 0;
    color: #d6d6d6;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    :hover {
      transition: 0.1s;
      color: white;
    }
    :active {
      transition: 0.1s;
      color: #b8b8b8;
    }
  }
`;

const Triangle = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  const goto = (path) => {
    history.push(path);
  };

  return (
    <>
      <TriContainer
      // className={`triangle ${show ? "open" : "close"}`}
      >
        <TriangleSVG
          onClick={() => {
            setShow(!show);
          }}
          className={`triangle ${show ? "open" : "close"}`}
          size={20}
        />
        <TransitionGroup>
          {show ? (
            <CSSTransition classNames="link-list" timeout={600}>
              <LinkList>
                <h3 onClick={() => goto("/#provenance")}>Provenance</h3>
                <h3 onClick={() => goto("/#faq")}>FAQ</h3>
                <h3 onClick={() => goto("/#about")}>About</h3>
              </LinkList>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </TriContainer>
    </>
  );
};

export default Triangle;
