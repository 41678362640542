import styled from "styled-components";
import { FaTwitter, FaInstagram } from "react-icons/fa";

const FooterContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  padding: 50px 0 50px 0;
`;
const LegalSocial = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: max(auto, 5rem);
  p {
    align-self: center;
    width: fit-content;
    font-family: Urbanist;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
  }
  svg {
    align-self: center;
    width: fit-content;
    margin: 6px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <LegalSocial>
        {/* <FaTwitter size="1.5rem" /> */}
        {/* <FaInstagram size="1.5rem" /> */}
        <p>© 2021 HODLPIGS</p>
      </LegalSocial>
    </FooterContainer>
  );
};

export default Footer;
