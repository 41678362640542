import { forwardRef } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import NickImg from "../../assets/us/Nick.jpg";
import SidImg from "../../assets/us/Sid.jpg";
import LunaImg from "../../assets/us/Luna.jpg";
import DimitriImg from "../../assets/us/Dimitri.jpg";

const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #d0c8e0;
  flex-wrap: wrap;
  margin: 5% 0;
`;

const AboutTitle = styled.h1`
  font-size: 5rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
  color: white;
  flex-basis: 100%;
  @media only screen and (max-width: 500px) {
    font-size: 3.5rem;
  }
`;

const AboutLeft = styled.div`
  align-self: center;
  flex-basis: 50%;
  width: 50%;
  @media only screen and (max-width: 500px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const AboutRight = styled.div`
  width: 50%;
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const AbtImg = styled.div`
  flex-basis: 30%;
  padding: 1rem;
  height: auto;

  img {
    width: 100%;
    border-radius: 5px;
  }
`;

const AboutContent = styled.div`
  h1 {
    color: white;
    font-size: 2rem;
  }
  p {
    color: #e7e4ed;
    font-size: 1.25rem;
  }

  a {
    font-size: 2rem;
    font-weight: bold;
  }

  a.raid {
    background-image: -webkit-linear-gradient(
      60deg,
      rgb(78, 199, 255) 20%,
      #d400ff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  a.stand {
    background-image: -webkit-linear-gradient(80deg, rgb(0, 195, 255), #00ff6a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a.dimitri {
    background: rgb(76, 249, 253);
    background: linear-gradient(
      45deg,
      rgba(27, 35, 255, 1) 0%,
      rgba(76, 249, 253, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a.luna {
    background: rgb(238, 61, 50);
    background: linear-gradient(
      45deg,
      rgba(238, 61, 50, 1) 0%,
      rgba(253, 231, 76, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  :hover {
    color: inherit;
    cursor: pointer;
  }
  :visited {
    color: inherit;
  }
`;

const Emoji = styled.span`
  font-size: 2rem;
  margin-right: 8px;
`;

const About = forwardRef((_, ref) => {
  return (
    <AboutContainer ref={ref}>
      <AboutTitle>About</AboutTitle>
      <AboutLeft>
        <AboutContent>
          <Emoji> 🎨</Emoji>
          <a
            className="stand"
            href="https://displate.com/refresh?art=611d465e025a9"
            target="_blank"
            rel="noreferrer"
          >
            Standardflinch
          </a>
          <p>... what about? </p>
          <Emoji> 💻</Emoji>
          <a
            className="raid"
            href="https://www.youtube.com/channel/UC5nxkMIehpWmHdd7hOLHTIw"
            target="_blank"
            rel="noreferrer"
          >
            Raid55
          </a>
          <p>I want my MTV</p>
          <Emoji> 🚀</Emoji>
          <a className="dimitri">Bacon</a>
          <p>submissive and breedable</p>
          <Emoji> 🦴</Emoji>
          <a className="luna">Luna</a>
          <p>Mad keep away player</p>
        </AboutContent>
      </AboutLeft>
      {isMobile ? null : (
        <AboutRight>
          <AbtImg>
            <img src={SidImg} />
            {/* <h2>standardFlinch</h2> */}
          </AbtImg>
          <AbtImg>
            <img src={NickImg} />
            {/* <h2>Raid55</h2> */}
          </AbtImg>
          <AbtImg>
            <img src={DimitriImg} />
            {/* <h2>Dimitri</h2> */}
          </AbtImg>
          <AbtImg>
            <img src={LunaImg} />
            {/* <h2>Luna</h2> */}
          </AbtImg>
        </AboutRight>
      )}

      {/* <h3>
        {" "}
        Made by{" "}
        <a
          href="https://www.youtube.com/channel/UC5nxkMIehpWmHdd7hOLHTIw"
          target="_blank"
          rel="noreferrer"
          className="raid"
        >
          Raid55
        </a>{" "}
        and{" "}
        <a
          href="https://displate.com/refresh?art=611d465e025a9"
          target="_blank"
          rel="noreferrer"
          className="stand"
        >
          StandardFlinch
        </a>
      </h3> */}
    </AboutContainer>
  );
});

export default About;

// h4 {
//   font-size: 1.2rem;
//   margin: 0;
//   margin-bottom: 0.5rem;
//   font-weight: normal;
// }
// h5 {
//   font-size: 1rem;
//   font-weight: bold;
// }
// h3 {
//   font-size: 0.9rem;
//   a {
//     font-weight: 700;
//     font-size: 1.1rem;
//     text-decoration: none;
//     cursor: pointer;
//   }
//   a.raid {
// background-image: -webkit-linear-gradient(
//   60deg,
//   rgb(78, 199, 255) 20%,
//   #d400ff 100%
// );
// -webkit-background-clip: text;
// -webkit-text-fill-color: transparent;
//   }
//   a.stand {
// background-image: -webkit-linear-gradient(
//   80deg,
//   rgb(0, 195, 255),
//   #00ff6a
// );
// -webkit-background-clip: text;
// -webkit-text-fill-color: transparent;
//   }

//   :hover {
//     color: inherit;
//   }
//   :visited {
//     color: inherit;
//   }
