/* eslint-disable */
const baseStyle = {
  borderRadius: "radii.1",
  border: "muted",
};

const appearances = {};

const sizes = {};

export default {
  baseStyle,
  appearances,
  sizes,
};
