/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "evergreen-ui";
import { FaTwitter, FaInstagram, FaDiscord } from "react-icons/fa";
import {
  Switch,
  Route,
  // Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { isMobile } from "react-device-detect";

import LogoSwap from "../../assets/svg/LogoSwap.svg";

import Pictures from "./Pictures/index";
import Buy from "./Buy";
import How from "./How";
import Arrow from "./Arrow";
import About from "./About";
import Provenance from "./Provenance";
import FAQ from "./FAQ";

import "./index.css";

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: 50%;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;
const Presentation = styled.div`
  width: 50%;
  position: relative;
  @media only screen and (max-width: 500px) {
    height: 301px;
    width: 80%;
    margin: 0 auto;
    margin-top: -50px;
    order: -1;
  }
`;

const Header = styled.h1`
  font-size: 6.3vw;
  font-weight: 900;
  margin: 0;
  color: #e8e1f5;
`;

const InfoText = styled.p`
  margin-left: 5px;
  max-height: 150px;
  max-width: 25rem;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: justify;
  color: #e7e4ed;

  @media only screen and (max-width: 500px) {
    font-size: 12px;
    width: 85%;
  }
  span {
    font-weight: bold;
  }
  span.eth {
    background-image: -webkit-linear-gradient(
      255deg,
      rgb(36, 234, 255),
      #a47af1
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const GetPigContainer = styled.div`
  /* background-color: #412c66; */
  border-radius: 5px;
  padding: 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  margin-top: 8px;
  /* margin: 1.5rem 5px 5px 5px; */

  h2 {
    font-weight: bold;
    font-size: 1.1rem;
    background-color: #b692f7;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  svg {
    margin: 0.5rem;
  }
`;

const MintNow = styled(Button)`
  height: 61px;
  width: 124px;
  padding: 8px, 16px, 8px, 16px;
  background: linear-gradient(270deg, #3366ff 0%, #52bd95 100%);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`;

const MintAndScroll = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  a {
    margin: auto 14px;
    text-decoration: none;
    @media only screen and (max-width: 500px) {
      margin: auto 4px;
    }
  }

  a.twitter {
    margin-left: 2rem;
    @media only screen and (max-width: 500px) {
      margin-left: 0.5rem;
    }
    transition: 0.2s;
    color: #1da1f2;
    :visited {
      color: #1da1f2;
    }
    :hover {
      transition: 0.2s;
      color: #71c9ff;
    }
  }
  a.insta {
    transition: 0.2s;
    color: #e1306c;

    :visited {
      color: #e1306c;
    }
    :hover {
      transition: 0.2s;
      color: #ffdc80;
    }
  }
  a.discord {
    transition: 0.2s;
    color: #5865f2;

    :visited {
      color: #5865f2;
    }
    :hover {
      transition: 0.2s;
      color: #848ef7;
    }
  }
`;

const MarginTest = styled.div`
  margin: 0 5%;
`;

const Home = () => {
  const { hash } = useLocation();
  const isBuy = useRouteMatch("/buy");
  const provenanceRef = useRef(null);
  const faqRef = useRef(null);
  const aboutRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (!provenanceRef) return;
    if (!faqRef) return;
    if (!aboutRef) return;
    switch (hash) {
      case "#provenance":
        provenanceRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "#faq":
        faqRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "#about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  }, [hash]);

  const gotoBuy = () => {
    history.push("/buy");
  };

  return (
    <>
      {/* <CornerDialog
        title="Don't forget to follow on Twitter and Instagram"
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        hasCancel={false}
        containerProps={{ hasFooter: false }}
      >
        We are giving away 10 pigs! 5 on Instagram and 5 on Twitter.
      </CornerDialog> */}
      <HeadContainer>
        <Content>
          <Header>Gift, Sell, {isMobile ? null : <br />} HODL</Header>
          <InfoText>
            <span>HODLPIGS</span> are a collection of <span>10,000</span> unique{" "}
            <span className="eth">Ether</span> piggy banks. Generated from over
            <span> 180</span> attributes, these wild looking piggy banks were
            not engineered with a cork on their bottoms. Accessing the funds may
            require blunt force.
          </InfoText>
          <MintAndScroll>
            <GetPigContainer>
              <MintNow onClick={gotoBuy} marginRight={16} appearance="primary">
                Mint now
              </MintNow>
              <a
                className="twitter"
                href="https://twitter.com/hodlpigs"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size="2rem" />
              </a>
              <a
                className="insta"
                href="https://www.instagram.com/hodlpigs"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size="2rem" />
              </a>
              <a
                className="discord"
                href="https://discord.gg/dkenHwXpma"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord size="2rem" />
              </a>
            </GetPigContainer>
            <div></div>
          </MintAndScroll>
        </Content>
        <Presentation>
          <Pictures />
        </Presentation>

        <Buy show={isBuy} />
      </HeadContainer>
      <Arrow />
      <How />
      <MarginTest>
        <Provenance ref={provenanceRef} />
        <FAQ ref={faqRef} />
        <About ref={aboutRef} />
      </MarginTest>
    </>
  );
};

export default Home;
