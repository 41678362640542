/* eslint-disable */
import { forwardRef } from "react";
import styled from "styled-components";

import Collapse from "../../components/Collapse";

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h2 {
    color: #fff;
    margin: 5% 0;
    font-size: 5rem;
    @media only screen and (max-width: 500px) {
      font-size: 3.5rem;
    }
  }
`;

const Holder = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  @media only screen and (max-width: 1117px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 1rem;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  /* align-items: center; */
`;

const FAQ = forwardRef((_, ref) => {
  return (
    <FAQContainer ref={ref}>
      <h2>FAQ</h2>
      <Holder>
        <Column>
          <Collapse
            emoji="👛"
            title="Are HODLPIGS Ethereum wallets?"
            content="No. HODLPIGS is a smart contract that manages a ledger. The ledger writes the balance that a pig holds, and the contract's wallet holds all the ether."
          />
          <Collapse
            emoji="🏧"
            title="How do I withdraw funds from my Piggy Bank?"
            content="The only way to withdraw funds from a HODLPIG is to crack it, thus burning it and removing it from circulation. This is a one-time action and cannot be undone."
          />
          <Collapse
            emoji="🔥"
            title="What does burning an NFT do?"
            content="Burning an NFT removes it from circulation, this is usually an admin tool to help with a bad mint, but we are using it here to represent breaking a piggy bank."
          />
          <Collapse
            emoji="🛒"
            title="How does the pre-sale work?"
            content="During the pre-sale the pig's final starting index is hidden. This mean that during pre-sale no one can know which pig they will get, anyone has a chance at the rarest ones."
          />
          <Collapse
            emoji="⏱"
            title="When does the pre-sale end?"
            content="Pre-sale ends on the 25th of October or if we sell out, whichever comes first."
          />
          <Collapse
            emoji="💸"
            title="How much does a HODLPIG cost?"
            content="Each pig cost 0.05 Ether. No Bonding Curves, flat price all the way to 10000. Gas Costs not included."
          />
        </Column>
        <Column>
          <Collapse
            emoji="👑"
            title="Will there be royalties?"
            content="No royalties :)"
          />
          <Collapse
            emoji="🏗️"
            title="How are they generated?"
            content="We created multiple parts for each layer and assigned weights to them, making some rarer than others. The rest is just psudo-random generation."
          />
          <Collapse
            emoji="⛏️"
            title="How many are pre-minted?"
            content="We reserve the first 50 pigs. First 3 are for the team members, 10 ar for giveaways, and the rest for friends."
          />
          <Collapse
            emoji="🖼️"
            title="Does a HODLPIG behave like regular NFT?"
            content="Absolutly! HODLPIGS is ERC-721 compliant, it just has extra functionality to handle the piggy bank feature."
          />
          <Collapse
            emoji="🐷"
            title="How can I get one?"
            content="You can mint one on this site while supply lasts. After that you will have to pick one up on a second-hand market like OpenSea"
          />
          <Collapse
            emoji="😃"
            title="Do you want to go see a movie?"
            content="I'm feeling FAT...and SASSY"
          />
        </Column>
      </Holder>
    </FAQContainer>
  );
});

export default FAQ;
