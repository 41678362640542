/* eslint-disable */
import {
  Button,
  Spinner,
  Position,
  Tooltip,
  Badge,
  toaster,
} from "evergreen-ui";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { Img } from "react-image";
import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router-dom";

import {
  ETHERSCAN_URL,
  IPFS_NODE_PREEFIX,
  CONTRACT_ADDRESS,
  OPEN_SEA_URL,
} from "../../config";
import { abi } from "../../assets/ABI/Pigs.json";
import { useFetchPigs } from "./hooks";
import Wallet from "../../assets/svg/pig/Wallet.svg";
import TmpImg from "../../assets/91.png";
import SideCard from "../SideCard";

const MyBTN = styled.button`
  /* width: 70px; */
  /* :hover ; */

  /* margin: 0 0 auto 0; */
  margin: auto 0;
  margin-left: 12px;
  border: none;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  background: none;
  /* border-radius: 0 0 10px 10px; */
  border-radius: 10px;
  padding: 8px 29px;
  color: #ccd5ff;
  transition: 0.2s;

  :hover {
    color: #ebefff;
    transition: 0.2s;
  }

  span {
    font-family: "Urbanist";
  }
`;
const MyPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  position: fixed;
  right: 10px;
  top: 15%;
  height: 537px;
  width: 281px;
  margin: 0;
  border-radius: 10px;
  background: linear-gradient(90.75deg, #0f1241 0.71%, #1f2176 83.33%);
  z-index: 10;
  overflow: hidden;
`;
/* animation: 0.4s ${({ isShown }) =>
    isShown ? FadeInAnime : FadeOutAnime}; */

const View = styled.div`
  height: 90%;

  hr {
    margin: 0;
    margin-bottom: 8px;
  }
`;

const FullImg = styled.img`
  width: 100%;
  border-radius: 5px;
`;

const ActionBar = styled.div`
  display: flex;

  button {
    height: 2rem;
    flex-grow: 1;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
    color: #ffffff;
  }

  button:first-child {
    margin-right: 5px;
    background-color: #da2a82;
    transition: 0.2s;

    :hover {
      background-color: #ec4498;
      transition: 0.2s;
      box-shadow: 0px 0px 1px 1px #ec4498;
      cursor: pointer;
    }
  }
  button:last-child {
    background-color: #006bf7;
    margin-left: 5px;
    transition: 0.2s;

    :hover {
      background-color: #1b7eff;
      transition: 0.2s;
      box-shadow: 0px 0px 1px 1px #1b7eff;
      cursor: pointer;
    }
  }
`;

const Owner = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: auto;
  object {
    padding-bottom: 3px;
    width: 34px;
    margin-right: 10px;
  }
  h6 {
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
    margin-bottom: auto;
    margin: 0;
    cursor: pointer;
  }
  button {
    margin-left: auto;
  }
`;

const Name = styled.h3`
  display: flex;
  margin: 0;
  font-family: Urbanist;
  font-weight: bold;
  font-size: 1.3rem;
  align-self: flex-end;
  align-items: flex-start;
  margin-bottom: 8px;
  /* text-align: justified; */

  div {
    margin-top: auto;
    margin-right: 8px;
    cursor: pointer;
  }

  p {
    align-self: center;
    margin: 0;
    margin-top: -6px;
    span {
      font-family: Urbanist;
      align-self: flex-end;
      font-style: normal;
      text-align: justified;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 2px;
      color: #648bff;
    }
  }
`;

const HoverTip = styled.h6`
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #696f8c;
  margin: 10px 0;
`;

const Tagbar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Attr = styled(Badge)`
  margin: 0 8px 9px 0;
  font-family: "Urbanist";
  font-weight: bold;
`;

const BackArrow = styled.div`
  display: inline;
  align-self: center;
`;

const SelectPig = styled.div`
  height: 90%;
  overflow: auto;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const HoverContainer = styled.div`
  width: 45%;
  margin: 2.5%;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  height: fit-content;

  img {
    width: 100%;
    border-radius: 10px;
    height: auto;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .spinner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    div {
      margin: 0 auto;
      align-self: center;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
    /* height: 100%; */
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #24242460;

    h6 {
      font-family: Urbanist;
      font-style: normal;
      text-align: justified;
      font-weight: bold;
      font-size: 2rem;
      margin: 0;
      color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
  }

  :hover {
    /* transition: 0.5s; */
    outline: 4px solid white;
    transition: outline 0.1s linear;

    .overlay {
      opacity: 1;
    }
  }
  :active {
    /* transition: 0.5s; */
    outline: 2px solid white;
    transition: outline 0.1s linear;
  }
`;

const SpinnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  /* align-items: center; */

  div {
    margin: auto;
  }

  h4 {
    margin: auto auto 0;
    font-size: 1rem;
  }
`;

const MintNow = styled(Button)`
  height: 61px;
  width: 124px;
  padding: 8px, 16px, 8px, 16px;
  background: linear-gradient(270deg, #3366ff 0%, #52bd95 100%);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin: 8px auto auto;
`;

const badgeColorMap = {
  Background: "#9DB5FF",
  Pedestal: "#D0CAF4",
  Body: "#A3E6CD",
  Nose: "#C1C4D6",
  Eyes: "#FFDFA6",
  Forehead: "#A8EAEF",
  Coin: "#F2C8B6",
  Side: "#EFA8BD",
};

// const badgeColorMap = {
//   "G Blue Green": "#9DB5FF",
//   "Floating Island": "#D0CAF4",
//   Perscription: "#FFDFA6",
//   "Pigy Bank Pink": "#A3E6CD",
//   Processor: "#A8EAEF",
//   "Snakk Nyustache": "#C1C4D6",
//   Panckakeswap: "#F2C8B6",
//   "Lightsaber Purple": "#EFA8BD",
// };

const HoverImg = ({ click, src, children }) => {
  return (
    <HoverContainer onClick={click}>
      <Img
        src={src}
        loader={
          <div className="spinner">
            <Spinner size={100} />
          </div>
        }
      />
      <div className="overlay">
        <h6>#{children}</h6>
      </div>
    </HoverContainer>
  );
};

const MiniPigView = ({ close, back, pig }) => {
  const history = useHistory();

  const externalLink = (link) => {
    window.open(link, "_blank");
  };

  const goto = (path) => {
    close();
    history.push(path);
  };

  return (
    <View>
      <Name>
        <BackArrow onClick={back}>
          <IoIosArrowBack />
        </BackArrow>
        <p>
          HODLPIG&nbsp; <span> #{pig.tokenId}</span>
        </p>
      </Name>
      {/* <hr /> */}
      <FullImg src={pig.image} />
      <ActionBar>
        <button onClick={() => goto(`/pig/${pig.tokenId}`)}>Manage</button>
        <button
          onClick={() =>
            externalLink(
              `${OPEN_SEA_URL}/assets/${CONTRACT_ADDRESS}/${pig.tokenId}`
            )
          }
        >
          OpeanSea
        </button>
      </ActionBar>
      <HoverTip>Hover over to view layer</HoverTip>
      <Tagbar>
        {pig.attributes.map((attr) => (
          <Tooltip
            key={attr.trait_type}
            content={attr.trait_type}
            position={Position.TOP}
          >
            <Attr color={badgeColorMap[attr.trait_type]} isInteractive>
              {attr.value}
            </Attr>
          </Tooltip>
        ))}
      </Tagbar>
    </View>
  );
};

const SelectPigView = ({ close, select, pigs }) => {
  return (
    <View>
      <Name>
        <BackArrow onClick={close}>
          <IoIosClose size={34} />
        </BackArrow>
        <p>Select a Pig</p>
      </Name>
      <SelectPig>
        {pigs.map((pig) => (
          <HoverImg
            click={() => select(pig.tokenId)}
            // src={`${IPFS_NODE_PREEFIX}/${pig.image.split("//").pop()}`}
            src={pig.image}
            key={pig.tokenId}
          >
            {pig.tokenId}
          </HoverImg>
        ))}
      </SelectPig>
    </View>
  );
};

const MyHODL = ({ show }) => {
  const [loading, setLoading] = useState(true);
  const [showWindow, setShowWindow] = useState(false);
  const [selectedPig, setSelectedPig] = useState(null);
  const { account, deactivate } = useWeb3React();
  const history = useHistory();
  const loadedPigs = useFetchPigs();

  useEffect(() => {
    if (loadedPigs) setLoading(false);
  }, [loadedPigs, setLoading]);

  const addrShort = (addr) => {
    return [
      addr.substring(0, 8),
      "...",
      addr.substring(addr.length - 6, addr.length),
    ].join("");
  };

  const externalLink = (link) => {
    window.open(link, "_blank");
  };

  const logout = () => {
    deactivate();
    setSelectedPig(null);
    setShowWindow(false);
    sessionStorage.removeItem("authed");
    toaster.notify("Disconnected Metamask account");
  };

  const closeCard = () => {
    setShowWindow(false);
    setSelectedPig(null);
  };

  const gotoBuy = () => {
    setShowWindow(false);
    history.push("/buy");
  };

  return (
    <>
      <MyBTN onClick={() => setShowWindow(true)}>
        MY <span>HODLPIGS</span>
      </MyBTN>
      {/* {showWindow && ( */}
      <SideCard show={showWindow} close={closeCard}>
        <MyPanel>
          {loading ? (
            <SpinnerDiv>
              <Spinner size={100} />
            </SpinnerDiv>
          ) : selectedPig ? (
            <MiniPigView
              back={() => setSelectedPig(null)}
              close={closeCard}
              pig={loadedPigs.find((pig) => pig.tokenId === selectedPig)}
            />
          ) : loadedPigs.length == 0 ? (
            <SpinnerDiv>
              <h4>You don't own one.</h4>
              <MintNow onClick={gotoBuy} marginRight={16} appearance="primary">
                Mint now
              </MintNow>
            </SpinnerDiv>
          ) : (
            <SelectPigView
              pigs={loadedPigs}
              select={setSelectedPig}
              close={closeCard}
            />
          )}
          <Owner>
            <object aria-label="Buy icon" type="image/svg+xml" data={Wallet} />
            <h6
              onClick={() =>
                externalLink(`${ETHERSCAN_URL}/address/${account}`)
              }
            >
              {addrShort(account)}
            </h6>
            <Button onClick={logout} appearance="primary" intent="danger">
              Disconnect
            </Button>
          </Owner>
        </MyPanel>
      </SideCard>
    </>
  );
};

export default MyHODL;
