import { Portal, Overlay } from "evergreen-ui";
// import styled from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./index.css";

const SideCard = ({ show = false, close = () => {}, children }) => {
  return (
    <Portal>
      <Overlay
        containerProps={{
          className: "bourdain bro",
        }}
        isShown={show}
        onBeforeClose={close}
        preventBodyScrolling
        shouldCloseOnClick
      >
        <div></div>
      </Overlay>
      <TransitionGroup>
        {!show ? null : (
          <CSSTransition classNames="panel" timeout={{ enter: 400, exit: 400 }}>
            {children}
          </CSSTransition>
        )}
      </TransitionGroup>
    </Portal>
  );
};

export default SideCard;
