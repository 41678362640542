import styled from "styled-components";
// import { Helmet } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  // useRouteMatch,
  // useHistory,
} from "react-router-dom";
import { ThemeProvider } from "evergreen-ui";
import { Web3ReactProvider } from "@web3-react/core";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

import Navbar from "./components/Navbar";
import Home from "./containers/Home";
import Pig from "./containers/Pig";
import Footer from "./components/Footer";
import theme from "./theme";
import { ALCHEMY_URL } from "./config";

function getLibrary(provider) {
  const library = new createAlchemyWeb3(ALCHEMY_URL, {
    writeProvider: provider,
  });
  sessionStorage.setItem("authed", "1");
  return library;
}

const AppContainer = styled.div`
  margin: 0 17.5%;
  @media only screen and (max-width: 500px) {
    margin: 0;
    overflow-x: hidden;
    /* padding: 0 5%; */
  }
  @media only screen and (max-width: 912px) {
    margin: 0 5%;
  }
`;

const App = () => {
  return (
    <ThemeProvider value={theme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <AppContainer>
            <Navbar />
            <Switch>
              <Route exact path={["/", "/buy"]} component={Home} />
              {/* <Route path="/buy" component={Home} />/
              <Route path="/myhodl" component={Home} />/ */}
              <Route path="/browse">
                <Redirect to={`https://opensea.io`} />
              </Route>
              {/* <Route path="/stats">
                <Redirect to={`http://dune.xyz`} />
              </Route> */}
              <Route exact path="/pig/:id" component={Pig} />
              <Redirect to={`/`} />
            </Switch>
            <Footer />
          </AppContainer>
        </Router>
      </Web3ReactProvider>
    </ThemeProvider>
  );
};

export default App;
