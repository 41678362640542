import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import axios from "axios";

import { abi } from "../assets/ABI/Pigs.json";
import {
  ALCHEMY_URL,
  CONTRACT_ADDRESS,
  TOTAL_SUPPLY,
  IPFS_NODE_PREEFIX,
} from "../config";

const web3 = new createAlchemyWeb3(ALCHEMY_URL);
web3.eth.handleRevert = true;
const contract = new web3.eth.Contract(abi, CONTRACT_ADDRESS);

const getSupplyLeft = () => {
  return contract.methods
    .mintIdx()
    .call()
    .then((res) => TOTAL_SUPPLY - res);
};

const getPigLedger = (id) => {
  return contract.methods
    .ledger(id)
    .call()
    .then((wei) => web3.utils.fromWei(wei, "ether"))
    .then((eth) => Number(eth).toFixed(4))
    .catch((e) => {
      console.log(e);
      return null;
    });
};

const getPigMetadata = (id) => {
  let tmpUri;
  return contract.methods
    .tokenURI(id)
    .call()
    .then((b) => b.replace("ipfs://", `${IPFS_NODE_PREEFIX}/`))
    .then((uri) => {
      tmpUri = uri;
      return uri;
    })
    .then(axios)
    .then((res) => {
      return {
        ...res.data,
        image: `${IPFS_NODE_PREEFIX}/${res.data.image.split("/").pop()}`,
        tokenUri: tmpUri,
      };
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

const getPigOwner = (id) => {
  return contract.methods
    .ownerOf(id)
    .call()
    .catch((e) => {
      console.log(e);
      return null;
    });
};

// get all tokens from index of balance of

export { getSupplyLeft, getPigLedger, getPigMetadata, getPigOwner };
