import { forwardRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { ETHERSCAN_URL, CONTRACT_ADDRESS } from "../../config";
import LogoSwap from "../../assets/svg/LogoSwap.svg";

const ProvenanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;
  margin: 10% auto 2% auto;
`;

const Datainfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  flex: 30%;
  @media only screen and (max-width: 500px) {
    max-width: 100%;
    flex: 100%;
  }
`;

const SvgAnimation = styled.div`
  /* flex-grow: 25%; */
  align-self: center;
  width: 45%;
  margin-left: 5%;
  object {
    width: 100%;
    margin-top: -3rem;
  }
`;

const Title = styled.h1`
  font-size: 5rem;
  @media only screen and (max-width: 500px) {
    font-size: 3.5rem;
  }
`;

const ProvInfo = styled.p`
  font-size: 1.25rem;
  font-weight: thin;
  text-align: justify;
  line-height: 1.75rem;
  color: #e7e4ed;
`;

const MoreInfo = styled.div`
  margin-top: 5%;
  color: #e7e4ed;
  width: 50%;
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.25rem;

    a {
      /* font-size: 0.8rem; */
      color: #8f59ef;
      text-decoration: none;
      :hover {
        color: #ab83f2;
      }
      :visited {
        color: #8f59ef;
      }
      @media only screen and (max-width: 500px) {
        font-size: 0.7rem;
      }
    }

    span {
      color: #9d96a8;
      @media only screen and (max-width: 500px) {
        font-size: 0.6rem;
      }
    }
  }
`;

const GoToProofs = styled.div`
  display: flex;
  margin-top: 5%;

  button {
    height: 2rem;
    flex-grow: 1;
    border: none;
    border-radius: 4px;
    background-color: #8f59ef;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 5%;

    height: 40px;
    color: #e7e4ed;

    transition: 0.2s;

    :hover {
      background-color: #a576f5;
      transition: 0.2s;
      box-shadow: 0px 0px 1px 1px #a576f5;
      cursor: pointer;
    }

    :active {
      background-color: #9268db;
      color: #dbdadb;
    }
  }
`;

const Provenance = forwardRef((_, ref) => {
  const history = useHistory();

  const goto = (path) => {
    history.push(path);
  };
  const externalLink = (link) => {
    window.open(link, "_blank");
  };
  return (
    <ProvenanceContainer ref={ref}>
      <Datainfo>
        <Title>Provenance Record</Title>
        <ProvInfo>
          The provenance record shows each HODLPIG that will ever exist. All
          images are hashed using the SHA-256 algorithm and a combined string is
          obtained by concatenating SHA-256 of each HODLPIG in their specific
          order. The final proof is obtained by SHA-256 hashing this combined
          string.
        </ProvInfo>
        <GoToProofs>
          <button onClick={() => externalLink("/public/provenance.json")}>
            Provenance
          </button>
          {/* <button onClick={() => goto("/records")}>Full Collection</button> */}
        </GoToProofs>
        <MoreInfo>
          <p>
            Initialized Starting Index:{" "}
            <span>(Revealed on the 25th of October)</span>
          </p>
          <p>
            Contract Address:{" "}
            <a
              href={`${ETHERSCAN_URL}/address/${CONTRACT_ADDRESS}`}
              target="_blank"
              rel="noreferrer"
            >
              0xb1b970658034dcF1FD0FEB24A20DA38A29AA5CCf
            </a>
          </p>
          <p>
            Final Proof Hash:{" "}
            <span>
              75804417186d360a948d7734e3273fd210c60a3968ff9a83201439d9deb11270
            </span>
          </p>
        </MoreInfo>
      </Datainfo>

      {isMobile ? null : (
        <SvgAnimation>
          <object
            aria-label="Animated SVG"
            type="image/svg+xml"
            data={LogoSwap}
          />
        </SvgAnimation>
      )}
    </ProvenanceContainer>
  );
});

export default Provenance;
