import styled, { keyframes } from "styled-components";

const arrowsAnimation = keyframes`
  0%{
    border-color:#b692f7;
    transform:translate(0,0);
  }
   20%{
    border-color:#b692f7;
     transform:translate(10px,10px);
  }
   20.1%,100%{
    border-color:rgb(36,234,255);
  }
`;

const ArrowContainer = styled.div`
  margin-top: 3rem;
  margin-left: -4px;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .indicator {
    position: relative;
    width: 50px;
    height: 50px;

    transform: rotate(45deg);
    span {
      position: absolute;
      left: 0;
      top: 0;
      width: 75%;
      height: 75%;
      /* font-weight: bolder; */
      box-sizing: border-box;
      border: none;
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
      animation: ${arrowsAnimation} 1s linear infinite;
      &:nth-child(1) {
        top: -20px;
        left: -20px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        top: -10px;
        left: -10px;
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        top: 0;
        left: 0;
        animation-delay: 0.4s;
      }
      /* &:nth-child(4) {
        top: 15px;
        left: 15px;
        animation-delay: 0.6s;
      }
      &:nth-child(5) {
        top: 30px;
        left: 30px;
        animation-delay: 0.8s;
      } */
    }
  }
`;

const TextAnimation = keyframes`
 from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
`;

const ScrollDown = styled.h4`
  margin: 0;
  margin-left: 0.01rem;
  margin-top: 3rem;
  /* margin: 0 45%; */
  /* writing-mode: vertical-rl; */
  /* text-orientation: mixed; */
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: bolder;
  background-image: -webkit-linear-gradient(180deg, #b692f7, rgb(36, 234, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${TextAnimation} 10s linear infinite;
`;
const HowItWorksContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  margin: 0 50%;
`;

const SomeContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Arrow = () => {
  return (
    <SomeContainer>
      <ScrollDown>How it Works</ScrollDown>
      <HowItWorksContainer>
        <ArrowContainer>
          <div className="indicator">
            <span></span>
            <span></span>
            <span></span>
            {/* <span></span>
          <span></span> */}
          </div>
        </ArrowContainer>
      </HowItWorksContainer>
    </SomeContainer>
  );
};

export default Arrow;
