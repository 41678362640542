/* eslint-disable */
import styled, { keyframes } from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// import TmpImg from "../../../assets/91.png";
import ImgSrc1 from "../../../assets/pigs/0.png";
import ImgSrc2 from "../../../assets/pigs/1.png";
import ImgSrc3 from "../../../assets/pigs/2.png";
import ImgSrc4 from "../../../assets/pigs/3.png";
import ImgSrc5 from "../../../assets/pigs/4.png";
import ImgSrc6 from "../../../assets/pigs/5.png";
import ImgSrc7 from "../../../assets/pigs/6.png";

import "./index.css";

const ImgTemplate = styled.img`
  border-radius: 15px;
  box-shadow: 0px 0px 30px black;
`;

const Anime = keyframes`
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)
  }

  25% {
    transform: translate(0px, 25px);
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)
  }

  50% {
    transform: translate(0px, 0px);
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)
  }

  75% {
    transform: translate(0px, 25px);
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)
  }

  100% {
    transform: translate(0px, 0px);
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95)
  }
`;

const Img1 = styled(ImgTemplate)`
  width: 40%;
  position: absolute;
  top: 11%;
  left: 40%;
  z-index: 5;
  animation: ${Anime} 16000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;
const Img2 = styled(ImgTemplate)`
  width: 34%;
  position: absolute;
  top: 28%;
  left: 10%;
  z-index: 4;
  animation: ${Anime} 14000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;
const Img3 = styled(ImgTemplate)`
  width: 44%;
  position: absolute;
  top: 52%;
  left: 40%;
  z-index: 3;
  animation: ${Anime} 22000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;
const Img4 = styled(ImgTemplate)`
  width: 24%;
  position: absolute;
  top: 62%;
  left: 18%;
  z-index: 2;
  animation: ${Anime} 20000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;
const Img5 = styled(ImgTemplate)`
  width: 24%;
  position: absolute;
  top: 28%;
  left: 78%;
  z-index: 1;
  animation: ${Anime} 18000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;
const Img6 = styled(ImgTemplate)`
  width: 24%;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1;
  animation: ${Anime} 18000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;
const Img7 = styled(ImgTemplate)`
  width: 20%;
  position: absolute;
  top: 78%;
  left: 90%;
  z-index: 1;
  animation: ${Anime} 18000ms linear infinite normal forwards;
  animation-iteration-count: infinite;
`;

const Pictures = () => {
  // const timeout = { enter: 4000, exit: 4000 };
  return (
    <>
      <Img1 src={ImgSrc1} />
      <Img2 src={ImgSrc2} />
      <Img3 src={ImgSrc3} />
      <Img4 src={ImgSrc4} />
      <Img5 src={ImgSrc5} />
      <Img6 src={ImgSrc6} />
      <Img7 src={ImgSrc7} />
    </>
  );
};
export default Pictures;
