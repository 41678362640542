/* eslint-disable */
import styled from "styled-components";
import { useState, useEffect } from "react";
import { Pill, Spinner } from "evergreen-ui";
import { useHistory } from "react-router-dom";
// import { Container, Row, Col } from "react-grid-system";
import { useWeb3React } from "@web3-react/core";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { isMobile } from "react-device-detect";

import { useEagerConnect, toastError } from "./hooks";
import injected from "../../utils/injected";
import BuyIcon from "../../assets/svg/Buy_Icon.svg";
import DonatetIcon from "../../assets/svg/Donate_Icon.svg";
import { getSupplyLeft } from "../../hooks/hodlPigs";
import MyHODL from "./MyHODL";
import Triangle from "./Triangle/index";

import "./index.css";

const NavContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
`;

const Logo = styled.h2`
  font-family: "Bungee";
  text-align: center;
  font-size: 1.9rem;
  margin: auto 0 2.5px 0;
  cursor: pointer;
`;

// const LinkContainer = styled.div`
//   width: 40%;
//   min-width: 300px;
//   display: flex;
//   margin: auto 0;
// `;

// const Link = styled.a`
//   font-weight: 500;
//   font-size: 1.2rem;
//   text-decoration: none;
//   cursor: pointer;
//   margin: 0 1rem;
//   color: #ccd5ff;
//   transition: 0.2s;
//   &:hover {
//     color: #ebefff;
//     transition: 0.2s;
//   }
//   &:visited {
//     color: inherit;
//   }
// `;

const ConnectWallet = styled.h3`
  margin: auto 0;
  text-align: right;
  cursor: pointer;
  font-size: 1.3rem;
  color: #e7e7e7;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  span {
    color: #f66a0a;
  }

  :hover {
    color: #fdfdfd;
    span {
      color: #ff8534;
    }
  }
`;

const LogoSupply = styled(Pill)`
  width: fit-content;
  margin: 0 0 0 auto;
  position: relative;
  display: block;
  color: white;
  font-family: "Urbanist";
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  background: linear-gradient(85.09deg, #fb3dc4 21.59%, #6c9eff 98.92%);
`;

// const LoggedIn = styled.div`
//   /* width: 70px; */
//   /* :hover ; */

//   /* margin: 0 0 auto 0; */
//   margin: auto 0;
//   text-align: right;
//   cursor: pointer;
//   font-size: 1.1rem;
//   background: linear-gradient(270deg, #6407bb 0%, #5405d3 100%);
//   /* border-radius: 0 0 10px 10px; */
//   border-radius: 10px;
//   padding: 14px 29px;

//   span {
//     font-family: "Bungee";
//   }
// `;

const LoginSpinner = styled(Spinner)`
  margin-left: 117px;
`;

const Navbar = () => {
  const [manageHover, setManageHover] = useState(false);
  const [supply, setSupply] = useState(null);
  const history = useHistory();
  const triedEager = useEagerConnect();
  const { error, activate, active, library } = useWeb3React();

  useEffect(() => {
    getSupplyLeft().then(setSupply).catch(console.log);
  }, [setSupply]);

  useEffect(() => {
    if (error) toastError(error);
  }, [error]);

  const goto = (path) => {
    history.push(path);
  };

  return (
    <nav>
      <NavContainer>
        <Logo onClick={() => goto("/")}>
          HODLPIGS <LogoSupply>{supply} left!</LogoSupply>
        </Logo>
        {isMobile ? null : <Triangle />}
        {/* <LinkContainer>
          <Link onClick={() => goto("/browse")}>Browse</Link>
          <Link onClick={() => goto("/stats")}>Stats</Link>
          <Link onClick={() => goto("#provenance")}>Provenance</Link>
          <Link onClick={() => goto("#faq")}>FAQ</Link>
          <Link onClick={() => goto("#about")}>About</Link>
        </LinkContainer> */}
        {!triedEager ? (
          <LoginSpinner size={70} />
        ) : active ? (
          <MyHODL />
        ) : (
          <ConnectWallet onClick={() => activate(injected)}>
            Connect <span>Metamask</span>
          </ConnectWallet>
        )}
      </NavContainer>
    </nav>
  );
};

export default Navbar;
