import { useState } from "react";
import styled from "styled-components";

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  background-color: #1e2246;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  margin: 0.5rem;
  align-items: space-around;
  height: fit-content;
  @media only screen and (max-width: 500px) {
    width: auto;
    /* margin: 0 auto; */
  }

  div {
    overflow-wrap: break-word;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0.8rem;
    align-items: center;
  }
  span {
    font-size: 1.5rem;
    margin-right: 4px;
  }
  h3 {
    font-size: 1.1rem;
    font-weight: normal;
  }

  p {
    font-size: 1rem;
    color: #e7e4ed;
    margin: 1rem;
  }
`;

const Collapse = ({ emoji, title, content }) => {
  const [show, setShow] = useState(false);
  return (
    <CollapseContainer onClick={() => setShow(!show)}>
      <div>
        <span>{emoji}</span>
        <h3>{title}</h3>
      </div>
      {show ? <p>{content}</p> : null}
    </CollapseContainer>
  );
};

export default Collapse;
