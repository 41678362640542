import { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { toaster } from "evergreen-ui";
import axios from "axios";

import { CONTRACT_ADDRESS, IPFS_NODE_PREEFIX } from "../../config";
import { abi } from "../../assets/ABI/Pigs.json";
import injected from "../../utils/injected";

export function toastError(error) {
  if (error instanceof NoEthereumProviderError) {
    toaster.danger(
      "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
    );
  } else if (error instanceof UnsupportedChainIdError) {
    toaster.danger("You're connected to an unsupported network.");
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    toaster.danger(
      "Please authorize this website to access your Ethereum account."
    );
  } else {
    console.error(error);
    toaster.danger(
      "An unknown error occurred. Check the console for more details."
    );
  }
}

export function useFetchPigs() {
  const { account, library, active } = useWeb3React();
  const [pigs, setPigs] = useState(null);

  useEffect(() => {
    const fetchPigs = async () => {
      if (!active) return;

      const contract = new library.eth.Contract(abi, CONTRACT_ADDRESS);

      const baseURI = await contract.methods
        .baseURI()
        .call()
        .then((b) => b.replace("ipfs://", `${IPFS_NODE_PREEFIX}/`))
        .catch((e) => {
          console.log(e);
          toaster.danger("There was an error: BASE URI FAILED");
        });

      const bal = await contract.methods
        .balanceOf(account)
        .call()
        .catch((e) => toaster.danger("There was an error: BAL CALL FAILED"));

      let _pigs = [];
      if (baseURI)
        for (var i = 0; i < bal; i++) {
          const pigId = await contract.methods
            .tokenOfOwnerByIndex(account, i)
            .call();

          const pig = await axios(baseURI + pigId)
            .then((res) => res.data)
            .catch((e) => {
              console.log(e);
              toaster.danger("There was an error: PIG FETCH");
            });

          _pigs.push({
            ...pig,
            image: `${IPFS_NODE_PREEFIX}/${pig.image.split("/").pop()}`,
            tokenId: pigId,
          });
        }

      setPigs(_pigs);
    };
    fetchPigs().catch((e) => {
      // i = bal;
      toaster.danger("There was an error: TOKEN OWNER INDEX FAILED");
    });
  }, []);

  return pigs;
}

export function useEagerConnect() {
  const [tried, setTried] = useState(false);
  const { activate, active } = useWeb3React();

  const authed = sessionStorage.getItem("authed");

  useEffect(() => {
    if (!authed) {
      setTried(true);
      return;
    }
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [tried, activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}
