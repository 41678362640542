/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  TextInput,
  Badge,
  Tooltip,
  Position,
  WarningSignIcon,
  Spinner,
  Pane,
  toaster,
  BankAccountIcon,
} from "evergreen-ui";
import { GiPerspectiveDiceSixFacesFive } from "react-icons/gi";
import { FaEthereum } from "react-icons/fa";
import { useWeb3React } from "@web3-react/core";
import { RandomReveal } from "react-random-reveal";
import { Img } from "react-image";

import {
  CONTRACT_ADDRESS,
  OPEN_SEA_URL,
  ETHERSCAN_URL,
  TOTAL_SUPPLY,
} from "../config";
import Wallet from "../assets/svg/pig/Wallet.svg";
import { getPigLedger, getPigMetadata, getPigOwner } from "../hooks/hodlPigs";
import { abi } from "../assets/ABI/Pigs.json";
import injected from "../utils/injected";

const PigContainer = styled.div`
  margin: 5% 10% 0;
  display: flex;
  flex-direction: column;
`;

const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const NameMeta = styled.div`
  display: flex;
  flex-direction: column;
`;
const Name = styled.h1`
  margin: 0;
  font-family: Bungee;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: justified;

  span {
    font-family: Urbanist;
    font-size: 36px;
    font-style: normal;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: justified;
    font-weight: 500;
    color: #3366ff;
  }
`;
const Owner = styled.div`
  display: flex;
  align-items: center;
  h6 {
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
    margin-bottom: auto;
    margin: 0;
    cursor: pointer;
  }
  object {
    padding-bottom: 3px;
    width: 34px;
    margin-right: 10px;
  }
  margin-bottom: 19px;
`;
const Random = styled(GiPerspectiveDiceSixFacesFive)`
  align-self: flex-start;
  margin-left: auto;
  margin-top: 14px;
`;

const Body = styled.div`
  height: 369px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const PigImg = styled(Img)`
  width: 369px;
  border-radius: 10px;
  border: 3px solid #1e0881;
  vertical-align: -webkit-baseline-middle;
`;
const InfoBox = styled.div`
  height: 369px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 31px;
`;
const Hodling = styled.h1`
  margin: 0;
  margin-top: auto;
  font-family: Urbanist;
  font-size: 48px;
  font-style: italic;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: justified;
`;
const Amount = styled.h1`
  margin: 0;
  background: linear-gradient(240.12deg, #eb00ff 45.43%, #00e0ff 108.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Urbanist";
  font-size: 64px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 00em;
  text-align: justified;
  line-height: 60px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding-left: 4px;
`;
const Actions = styled.div`
  height: 224px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ActionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h3 {
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: "Urbanist";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #c1c4d6;
    margin: 0;
  }
`;
const Deposit = styled(ActionDiv)`
  h3 {
    color: #52bd94;
  }
`;
const DepositForm = styled.div`
  position: relative;
  display: flex;
  min-width: 220px;
  input {
    width: 84px;
    background: #474d66;
    border: none;
    margin-right: 14px;
    color: white;
  }
  svg.eth-logo {
    position: absolute;
    /* margin: auto 0; */
    left: 55px;
    top: 5px;
    z-index: 100;
  }
`;
const Crack = styled(ActionDiv)`
  margin-left: 1.5rem;
  h3 {
    color: #c04242;
  }
`;
const CrackButton = styled(Button)`
  margin: 0 auto;
`;
const ViewOn = styled.div`
  h6 {
    font-family: "Urbanist";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: justified;
    margin: 10px 0;
  }
  button {
    padding: 8px 16px;
    margin-right: 14px;
    margin-bottom: 5px;
  }
  button.opensea {
    background-color: transparent;
    color: #3366ff;
    border: 1px solid #3366ff;
    :hover {
      background-color: #3366ff;
      color: white;
      border: 1px solid #3366ff;
    }
  }
  button.ipfs {
    background-color: transparent;
    color: #19778a;
    border: 1px solid #19778a;
    :hover {
      background-color: #19778a;
      color: white;
      border: 1px solid #19778a;
    }
  }
  button.rarity {
    background-color: transparent;
    color: #d14343;
    border: 1px solid #d14343;
    :hover {
      background-color: #d14343;
      color: white;
      border: 1px solid #d14343;
    }
  }
  button.airweave {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    :hover {
      background-color: white;
      color: black;
      border: 1px solid white;
    }
  }
`;
const Foot = styled.div`
  width: 369px;
  display: flex;
  flex-direction: column;
  margin-left: 14px;

  h6 {
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #696f8c;
    margin: 14px 0;
  }
`;
const Tagbar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Attr = styled(Badge)`
  margin: 0 14px 15px 0;
  font-family: "Urbanist";
  font-weight: bold;
`;

const badgeColorMap = {
  Background: "#9DB5FF",
  Pedestal: "#D0CAF4",
  Body: "#A3E6CD",
  Nose: "#C1C4D6",
  Eyes: "#FFDFA6",
  Forehead: "#A8EAEF",
  Coin: "#F2C8B6",
  Side: "#EFA8BD",
};

const Pig = () => {
  const [pig, setPig] = useState(null);
  const [validId, setValidId] = useState(null);
  const [depositValue, setDepositValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const history = useHistory();
  const { account, activate, active, library } = useWeb3React();
  let { id } = useParams();
  id = Number(id);

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset, setReset]);

  useEffect(() => {
    setLoading(true);
  }, [id, setLoading]);

  useEffect(() => {
    if (!isNaN(id) && id > -1 && id < TOTAL_SUPPLY + 1) setValidId(true);
    else setValidId(false);
  }, [id, setValidId]);

  useEffect(() => {
    if (validId === null) return;
    if (!validId) history.push("/404");
  }, [validId]);

  useEffect(() => {
    const loadData = async () => {
      if (validId === null) return;
      if (!validId) return;

      const metadata = await getPigMetadata(id);
      if (!metadata) throw new Error("error");

      const pigOwner = await getPigOwner(id);
      if (!pigOwner) throw new Error("error");

      const pigLedger = await getPigLedger(id);
      if (!pigLedger) throw new Error("error");

      setPig({
        ...metadata,
        owner: pigOwner,
        ledger: pigLedger,
      });
      setLoading(false);
    };

    loadData().catch((e) => {
      history.push("/");
      toaster.danger("Pig does not exist or has been burned");
    });
  }, [id, validId, setPig, getPigLedger, getPigOwner, getPigMetadata]);

  const externalLink = useCallback((link) => {
    window.open(link, "_blank");
  });

  const handleDeposit = ({ target }) => {
    if (!isNaN(Number(target.value))) setDepositValue(target.value);
  };

  const crackPig = useCallback(() => {
    setActionLoading(true);
    const contract = new library.eth.Contract(abi, CONTRACT_ADDRESS);
    contract.methods
      .crackPig(id)
      .send({ from: account })
      .then((tx) => {
        history.push("/");
        toaster.success(
          `Cracking Pig processed on chain. Transaction Hash: ${tx.transactionHash}`
        );
      })
      .catch((e) => {
        setActionLoading(false);
        toaster.danger(e.message);
      });
  });
  const depositPig = useCallback(() => {
    if (!active) return activate(injected);

    setActionLoading(true);
    const contract = new library.eth.Contract(abi, CONTRACT_ADDRESS);
    contract.methods
      .deposit(id)
      .send({ from: account, value: library.utils.toWei(String(depositValue)) })
      .then((tx) => {
        setActionLoading(false);
        toaster.success(
          `Deposit processed on chain. Transaction Hash: ${tx.transactionHash}`
        );
        setReset(true);
        setPig({
          ...pig,
          ledger: (Number(pig.ledger) + Number(depositValue)).toFixed(4),
        });
        setDepositValue(0);
      })
      .catch((e) => {
        setActionLoading(false);
        toaster.danger(e.message);
      });
  }, [
    id,
    active,
    activate,
    library,
    setActionLoading,
    setDepositValue,
    depositValue,
  ]);

  return !loading ? (
    <PigContainer>
      <Head>
        <NameMeta>
          <Name>
            HODLPIG <span>#{id}</span>
          </Name>
          <Owner>
            <object aria-label="Buy icon" type="image/svg+xml" data={Wallet} />
            <h6
              onClick={() =>
                externalLink(`${ETHERSCAN_URL}/address/${pig.owner}`)
              }
            >
              <RandomReveal
                isPlaying
                duration={2.5}
                revealDuration={1}
                characters={
                  active && account == pig.owner
                    ? `You (${pig.owner})`
                    : pig.owner
                }
              />
            </h6>
          </Owner>
          {/* <img src={Wallet} alt="wallet icon" />{" "} */}
        </NameMeta>
      </Head>
      <Body>
        <PigImg
          src={pig.image}
          alt={pig.name}
          loader={
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={374}
              width={374}
            >
              <Spinner size={164} />
            </Pane>
          }
        />
        <InfoBox>
          <div>
            <Hodling>HODLING</Hodling>
            <Amount>
              {reset ? null : (
                <RandomReveal
                  isPlaying
                  duration={2}
                  revealDuration={1}
                  characterSet={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                  characters={pig.ledger}
                />
              )}
              <FaEthereum color="lightgrey" size={40} />
            </Amount>
          </div>
          <Actions>
            <Deposit>
              <h3>Deposit</h3>
              <p>Enter an amount to deposit</p>
              <DepositForm>
                <TextInput
                  placeholder="0.05"
                  value={depositValue}
                  onChange={handleDeposit}
                  disabled={actionLoading}
                />
                <FaEthereum className="eth-logo" color="lightgrey" size={22} />
                <Button
                  appearance="primary"
                  intent="success"
                  onClick={depositPig}
                  iconBefore={BankAccountIcon}
                  isLoading={actionLoading}
                >
                  {active ? "Deposit" : "Connect"}
                </Button>
              </DepositForm>
            </Deposit>
            {active && account == pig.owner ? (
              <Crack>
                <h3>Crack</h3>
                <p>Break your pig</p>
                <CrackButton
                  appearance="primary"
                  intent="danger"
                  onClick={crackPig}
                  iconBefore={WarningSignIcon}
                  isLoading={actionLoading}
                >
                  CRACK PIG
                </CrackButton>
              </Crack>
            ) : null}
          </Actions>
          <ViewOn>
            <h6>View On</h6>
            <Button
              onClick={() =>
                externalLink(`${OPEN_SEA_URL}/assets/${CONTRACT_ADDRESS}/${id}`)
              }
              size="small"
              className="opensea"
            >
              OpenSea
            </Button>
            <Button
              onClick={() => externalLink(pig.tokenUri)}
              size="small"
              className="ipfs"
            >
              ipfs
            </Button>
            {/* <Button size="small" className="rarity">
              rarity.tools
            </Button>
            <Button size="small" className="airweave">
              airweave
            </Button> */}
          </ViewOn>
        </InfoBox>
      </Body>
      <Foot>
        <h6>Hover over to view layer</h6>
        <Tagbar>
          {pig.attributes.map((attr) => (
            <Tooltip
              key={attr.trait_type}
              content={attr.trait_type}
              position={Position.TOP}
            >
              <Attr color={badgeColorMap[attr.trait_type]} isInteractive>
                {attr.value}
              </Attr>
            </Tooltip>
          ))}
        </Tagbar>
      </Foot>
    </PigContainer>
  ) : (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={579}
    >
      <Spinner size={164} />
    </Pane>
  );
};

export default Pig;
